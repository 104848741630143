import React, {useEffect} from 'react';
import { navigate } from 'gatsby';

export default function Index() {
  useEffect(() => {
    navigate('/consultAstro', {
      replace: true,
    });
  }, []);

  return (
    <div></div>
  );
}